/* eslint-disable react/jsx-key */
import React from "react"

//logo de empresas clientes
import Culqi from "@components/sharedComponents/customersLogos/logos/Culqui.svg"
import Kontigo from "@components/sharedComponents/customersLogos/logos/Kontigo.svg"
import Rimac from "@components/sharedComponents/customersLogos/logos/Rimac.svg"
import Alfin from "@components/sharedComponents/customersLogos/logos/Alfin.svg"
import Escampa from "@components/sharedComponents/customersLogos/logos/Escampa.svg"
import ProEmpresa from "@components/sharedComponents/customersLogos/logos/Proempresa.svg"

//image acordeon left
import ImageAccordionLeftOne from "@components/pageAbm/pageFinanzas/image/imageOne.png"
import ImageAccordionLeftTwo from "@components/pageAbm/pageFinanzas/image/imageTwo.png"
import ImageAccordionLeftThree from "@components/pageAbm/pageFinanzas/image/imageThree.png"

import imageVideo from "@components/sharedComponents/bannerVideo/image/imageFintech.jpg"
import imgBanner from "@components/pageAbm/pageFinanzas/image/banner.png"

const titleStart = (<p className="guide__title">
    Cómo las <span style={{ color: '#00CA56' }}>Fintech</span>  pueden <span style={{ color: '#00CA56' }}>aumentar en +65% el contacto con sus leads </span> y multiplicar sus ventas con automatización omnicanal
</p>
)

const descripcionStart = (<p>
    La industria fintech ha crecido un 340% en Latam, pero el reto sigue siendo la contactabilidad y conversión de leads. El 75% de los clientes espera respuestas inmediatas, y las empresas que usan automatización han logrado +40% en ventas.
    <br /><br />
    Descubre cómo fintechs como Culqi, Kontigo y Escampa han transformado su prospección, aumentando su contactabilidad en 65% y multiplicando x8 sus ventas con tecnología omnicanal.
</p>)

const data = {
    start: {
        title: titleStart,
        textbody: descripcionStart,
        img: "",
        banner: imgBanner,
        inputText: "Puesto de trabajo",
    },
    customersDesktop: [
        Culqi,
        Kontigo,
        Rimac,
        Alfin,
        Escampa,
        ProEmpresa
    ],
    customersTablet: {
        arrayOne: [Culqi,
            Kontigo,
            Rimac,
            Alfin,
            Escampa,
            ProEmpresa],
    },
    contacts: {
        title: "Optimiza tu prospección y cierra más ventas con automatización",
        textbody: "Descubre cómo mejorar la eficiencia de tu equipo comercial con tecnología omnicanal e IA. Automatiza el primer contacto, segmenta leads y potencia tu conversión con una estrategia inteligente.",
        href: "/hablemos/",
        btn: "Quiero una asesoría gratuita",
    },
    accordionRight: [
        {
            title: "Uso de WhatsApp, email y llamadas automatizadas",
            description: "<p>Beex permite combinar WhatsApp, email y llamadas automáticas para generar interacciones fluidas en cada etapa del proceso de prospección. Desde un solo panel, puedes programar secuencias de contacto y asegurarte de que ningún prospecto quede sin respuesta. <br /><br />  El 89% de los consumidores prefiere empresas con múltiples canales de contacto, y Beex facilita la integración de estos medios en una estrategia unificada. Escampa aumentó en +30% su CSAT con comunicaciones masivas automatizadas, logrando un seguimiento más eficiente y mejorando la percepción de marca. </p>",
            image: ImageAccordionLeftOne
        },
        {
            title: "Automatización de respuestas inmediatas",
            description: "<p>Responder rápidamente a un lead puede ser la diferencia entre ganar o perder una venta. Con Beex, puedes automatizar respuestas en WhatsApp, email y chat, asegurando una atención inmediata sin depender de un agente disponible.<br /><br /> Los bots pueden calificar leads, resolver consultas y escalar conversaciones según el nivel de interés. Esto reduce el tiempo de espera y mejora la experiencia del usuario. El 75% de los consumidores espera respuestas inmediatas, y la automatización garantiza una atención sin interrupciones, impulsando conversiones. </p>",
            image: ImageAccordionLeftTwo
        },
        {
            title: "Segmentación inteligente para priorizar leads",
            description: "<p>No todos los leads tienen el mismo nivel de interés. Beex permite clasificar prospectos según comportamiento, fuente de contacto y nivel de interacción, priorizando aquellos con mayor probabilidad de conversión. Al integrar WhatsApp API y tu CRM, puedes activar rutas personalizadas para cada tipo de cliente, asegurando que reciba el mensaje adecuado en el momento preciso. <br /><br /> Las empresas que utilizan segmentación avanzada pueden aumentar sus ventas hasta un 40%, optimizando la asignación de recursos y enfocando los esfuerzos en clientes con mayor intención de compra./p>",
            image: ImageAccordionLeftThree,
        },
    ],
    footer: {
        product: "PRODUCTOS",
        productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
        productMobileOne: "Conversations",
        linkone: "/conversations/",
        productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
        productMobileTwo: "Contact Center",
        linktwo: "/contact-center/",
        productDesktopFour: "Validation: Software de validación de teléfono.",
        productMobileFour: "Validation",
        linkfour: "/validation/",

        solution: "SOLUCIONES",
        solutionOne: "Atención al cliente",
        linkfive: "/atencion-al-cliente/",
        solutionTwo: "Telemarketing",
        linksix: "/marketing-ventas/",
        solutionThree: "Contact Center",
        linkseven: "/contact-center/",
        solutionFour: "Cobranzas",
        linkeight: "/software-cobranzas/",
        solutionFive: "WhatsApp",
        linkwhatsapp: "/whatsapp-business-api/",
        linkchatbots: "/chatbots-online/",
        solutionSix: "Chatbots",
        linkinstagram: "/chatbot-instagram/",
        solutionSeven: "Instagram",
        linkwebchat: "/chat-en-vivo-web/",
        solutionEight: "WebChat",

        company: "EMPRESA",
        companyOne: "Nosotros",
        linknine: "/nosotros/",
        companyTwo: "Casos de éxito",
        linkten: "/casos-de-exito/",
        companyThree: "Blog",
        linkeleven: "https://beexcc.com/blog/",
        companyFour: "Hablemos",
        linktwelve: "/hablemos/",
        companySix: "Documentación",
        linkdocs: "https://beexcc.com/docs/",
        companyFive: "Recursos",
        linktthirteen: "/recursos/",

        blog: "BLOG",
        blogone: "¿Cómo elegir el mejor software para call center?",
        linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

        blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
        linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

        blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
        linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

        blogfour:
            "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
        linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

        blogfive: "9 características que las herramientas de telemarketing",
        linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

        masblog: "Conoce más sobre nuestro blog",
        linkmasblog: "https://beexcc.com/blog/",

        address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
        phone: "(+511) 277-4188",
        mail: "hi@beexcc.com",
        copyright: "Copyright© 2024 Beex Latam. Todos los derechos reservados.",
        privacyPolicy: "Política de Privacidad",
        privacyPolicyHref: "/politicas-privacidad",
        cookiesPolicy: "Política de Cookies",
        cookiesPolicyHref: "/politicas-cookies",
    },
    bannerVideo: {
        title: "¿Cómo implementar una estrategia de atención al cliente digital de una Fintech?",
        description: "<p>Aprende a estructurar una estrategia de atención al cliente digital para una Fintech. Explora los pasos clave, los canales más efectivos y las herramientas que facilitan la automatización.<br /><br />Entiende cómo optimizar la experiencia del usuario y mejorar la eficiencia operativa con tecnología omnicanal. Accede a información práctica para implementar soluciones digitales en tu empresa.</p>",
        image: imageVideo
    },
}

export default data
