import React, { useEffect, useRef } from "react"
import { TitleUnderlineOne, TitleUnderlineTwo } from "./titleUnderline"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import useNearScreen from "@hooks/useNearScreen"
import imageOneDesktop from "./images/imagesDesktop/imageOne.png"
import imageTwoDesktop from "./images/imagesDesktop/imageTwo.png"
import imageThreeDesktop from "./images/imagesDesktop/imageThree.png"
import imageOneMobile from "./images/imagesMobile/imageOne.webp"
import imageTwoMobile from "./images/imagesMobile/imageTwo.webp"
import imageFourMobile from "./images/imagesMobile/imageThree.webp"

const Experience = ({ location }) => {
  const { isNearScreen, fromRef } = useNearScreen({ distance: "10px" })
  const sliderRef = useRef()

  useEffect(() => {
    if (isNearScreen) sliderRef.current.slickPlay()
  }, [isNearScreen])

  const activateSliderTestimonies = {
    arrows: true,
    autoplay: false,
    // autoplay: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 14000,
  }

  const data = [
    {
      title: "Implementación de secuencias de seguimiento",
      description: "Muchos prospectos no responden en el primer intento, pero eso no significa que no estén interesados. Beex permite programar secuencias automáticas en WhatsApp, email y SMS para enviar recordatorios y mensajes personalizados según la etapa del embudo en la que se encuentren. <br /><br /> WhatsApp, con 98% de tasa de apertura, es ideal para este tipo de recontacto, asegurando que los mensajes sean leídos y generando un 30% más de engagement en comparación con campañas tradicionales. Esta estrategia reduce el abandono y aumenta las oportunidades de conversión.",
      image: imageOneMobile,
      background: "#D3F7FA"
    },
    {
      title: "Agentes para calificar y direccionar leads",
      description: "Los agentes de Beex pueden recopilar información clave desde el primer contacto, filtrando y direccionando prospectos según sus intereses. Esto permite que los agentes solo atiendan leads calificados, optimizando su tiempo y mejorando las tasas de conversión. <br /><br /> Un bot puede responder preguntas sobre productos, simular cotizaciones o programar llamadas con un asesor en segundos. El 74% de los usuarios prefiere resolver consultas simples con chatbots, lo que demuestra la importancia de tener una estrategia automatizada para agilizar la conversión de prospectos.",
      image: imageTwoMobile,
      background: "#FBE6E5"
    },
    {
      title: "Integración con CRM y plataformas externas",
      description: "Sin integración, los leads se pierden en múltiples sistemas y los equipos de ventas operan sin información completa. Beex permite conectar WhatsApp API, CRM y otras plataformas, asegurando que todos los datos estén centralizados en un solo lugar.<br /><br />Esto optimiza la trazabilidad de cada interacción, facilitando el seguimiento y mejorando la tasa de conversión. Culqi integró su CRM con Beex y aumentó su contactabilidad en +65%, demostrando el impacto de una infraestructura tecnológica conectada. Esta integración permite acciones automatizadas basadas en el historial del usuario, mejorando la toma de decisiones.",
      image: imageFourMobile,
      background: "#EEEAFB"
    },
  ]

  return (
    <section className="container-experience-pandero">
      <p className="container-experience-pandero-title">
        <TitleUnderlineOne underline="Utiliza la tecnología" /> <TitleUnderlineTwo underline="de Beex" />    para impulsar la prospección y aumenta las conversiones de tu empresa
      </p>

      <div className="container-experience-pandero-cardsOne">
        <div>
          <section className="container-experience-pandero-cardsOne-card" style={{ background: "#FBE6E5", padding: "32px 6px 32px" }}>
            <section style={{ padding: "0px 26px" }}>
              <p className="container-experience-pandero-cardsOne-card-title">Implementación de secuencias de seguimiento </p>
              <p className="container-experience-pandero-cardsOne-card-description">
                Muchos prospectos no responden en el primer intento, pero eso no significa que no estén interesados. Beex permite programar secuencias automáticas en WhatsApp, email y SMS para enviar recordatorios y mensajes personalizados según la etapa del embudo en la que se encuentren.
                <br /><br />
                WhatsApp, con 98% de tasa de apertura, es ideal para este tipo de recontacto, asegurando que los mensajes sean leídos y generando un 30% más de engagement en comparación con campañas tradicionales. Esta estrategia reduce el abandono y aumenta las oportunidades de conversión.
              </p>
            </section>
            <section>
              <img
                className="container-experience-pandero-cardsOne-card-image"
                src={imageOneDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
        <div className="container-experience-pandero-cardsTwo">
          <div className="container-experience-pandero-cardsOne-column">
            <section className="container-experience-pandero-cardsOne-card" style={{ background: "#EEEAFB", padding: "32px" }}>
              <section>
                <p className="container-experience-pandero-cardsOne-card-title">Agentes para calificar y direccionar leads</p>
                <p className="container-experience-pandero-cardsOne-card-description">
                  Los agentes de Beex pueden recopilar información clave desde el primer contacto, filtrando y direccionando prospectos según sus intereses. Esto permite que los agentes solo atiendan leads calificados, optimizando su tiempo y mejorando las tasas de conversión.
                  <br /><br />
                  Un bot puede responder preguntas sobre productos, simular cotizaciones o programar llamadas con un asesor en segundos. El 74% de los usuarios prefiere resolver consultas simples con chatbots, lo que demuestra la importancia de tener una estrategia automatizada para agilizar la conversión de prospectos.
                </p>
              </section>
              <section>
                <img
                  className="container-experience-pandero-cardsOne-card-image"
                  src={imageTwoDesktop}
                  alt="foto"
                  loading="lazy"
                />
              </section>
            </section>
          </div>
          <div className="container-experience-pandero-cardsTwo-cards-column">
            <section className="container-experience-pandero-cardsTwo-card" style={{ background: "#EEEAFB", padding: "32px 32px" }}>
              <section>
                <p className="container-experience-pandero-cardsTwo-card-title">Integración con CRM y plataformas externas</p>
                <p className="container-experience-pandero-cardsTwo-card-description">
                Sin integración, los leads se pierden en múltiples sistemas y los equipos de ventas operan sin información completa. Beex permite conectar WhatsApp API, CRM y otras plataformas, asegurando que todos los datos estén centralizados en un solo lugar.
<br /><br />
Esto optimiza la trazabilidad de cada interacción, facilitando el seguimiento y mejorando la tasa de conversión. Culqi integró su CRM con Beex y aumentó su contactabilidad en +65%, demostrando el impacto de una infraestructura tecnológica conectada. Esta integración permite acciones automatizadas basadas en el historial del usuario, mejorando la toma de decisiones.
               </p>
              </section>
              <section>
                <img
                  className="container-experience-pandero-cardsTwo-card-image"
                  src={imageThreeDesktop}
                  alt="foto"
                  loading="lazy"
                />
              </section>
            </section>
          </div>
        </div>
      </div>


      <div className="container-experience-pandero-cards-mobile" ref={fromRef}>
        <Slider {...activateSliderTestimonies} ref={sliderRef}>
          {data.map((elemento, i) => {
            return (
              <section key={i}>
                <div className="container-experience-pandero-cards-mobile-card" style={{ background: elemento.background }}>
                  <section>
                    <p className="container-experience-pandero-cards-mobile-card-title">{elemento.title}</p>
                    <p className="container-experience-pandero-cards-mobile-card-description" dangerouslySetInnerHTML={{ __html: elemento.description }}>

                    </p>
                  </section>
                  <section>
                    <img
                      className="container-experience-pandero-cards-mobile-card-image"
                      src={elemento.image}
                      alt="foto"
                      loading="lazy"
                    />
                  </section>
                </div>
              </section>
            )
          })}
        </Slider>
      </div>
    </section>
  )
}
export default Experience
